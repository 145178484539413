<template>
  <main>
    <!-- ===== Hero ===== -->
    <div class="gradient-x-overlay-sm-indigo position-relative overflow-hidden">
      <div class="container space-top-3 space-bottom-2">
        <div class="w-lg-75 text-center mx-lg-auto">
          <img class="img-fluid mb-5" src="@/assets/img/products/taptab-logo.png" style="max-width:15rem">
          <h1 class="mb-3">Transforming Education with AI Agents</h1>
          <p class="lead">Introducing TapTab - AI agents designed specifically for the education sector.</p>
          <a class="btn btn-success transition-3d-hover mt-3" href="https://taptab.io/" target="_blank">Explore More <i class="fas fa-external-link-alt ml-1" /></a>
        </div>
        <div class="position-relative w-90 w-md-50 mx-auto mt-6">
          <div class="position-relative z-index-2" data-aos="fade-up" data-aos-offset="-50">
            <img class="img-fluid rounded-lg" src="@/assets/svg/illustrations/ai-tutor.svg" alt="TapTab">
          </div>
        </div>
        <!-- <div class="position-relative w-90 w-md-75 mx-auto mt-6">
          <div class="position-relative z-index-2" data-aos="fade-up" data-aos-offset="-50">
            <img class="img-fluid rounded-lg" src="@/assets/img/products/fiberconnect/fiber-hero-img.png" alt="FiberConnect Portals">
          </div>
        </div> -->
      </div>
    </div>
    <!-- ===== End Hero ===== -->

    <!-- ===== Features ===== -->
    <div class="container space-2 space-lg-3">
      <!-- Feature 1 -->
      <div class="row align-items-lg-center mb-11">
        <div class="col-lg-6">
          <div class="mb-3 mb-lg-0">
            <h2 class="mb-3">Personalized Learning Experiences</h2>
            <p>TapTab's AI agents provide personalized learning paths, adjusting content and pace to the unique needs of each student, maximizing engagement and learning outcomes.</p>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-left">
          <div class="w-65 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/value-beknowledgeable.svg" alt="Personalized Learning Experiences">
          </div>
        </div>
      </div>
      <!-- Feature 2 -->
      <div class="row align-items-lg-center mb-11">
        <div class="col-lg-6 order-lg-2">
          <div class="mb-3 mb-lg-0">
            <h2 class="mb-3">Intelligent Tutoring</h2>
            <p>Our AI agents offer real-time, adaptive tutoring, providing students with targeted support and feedback to overcome knowledge gaps and excel in their studies.</p>
          </div>
        </div>
        <div class="col-lg-6 order-lg-1" data-aos="fade-right">
          <div class="w-90 w-md-75 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/consultancy-overview-1.svg" alt="Intelligent Tutoring">
          </div>
        </div>
      </div>
      <!-- Feature 3 -->
      <div class="row align-items-lg-center">
        <div class="col-lg-6 col-xl-6 mb-6 mb-lg-0">
          <h2 class="mb-3">Flexible Integrations</h2>
          <p>TapTab seamlessly integrates with a wide range of AI models, LLMs, and educational platforms, empowering institutions to leverage their existing technology investments.</p>

        </div>
        <div class="col-lg-6" data-aos="fade-left">
          <div class="w-90 w-md-75 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/img/products/ai-models.jpg" alt="Flexible Integrations">
          </div>
        </div>
      </div>
    </div>
    <!-- ===== End Features ===== -->

    <!-- ===== CTA Section ===== -->
    <div class="gradient-x-overlay-sm-indigo position-relative overflow-hidden text-center">
      <div class="container space-2">
        <div class="mb-6">
          <h2>Revolutionize your classroom with GenAI</h2>
          <p>Unlock the future of education with AI-driven solutions. Visit TapTab to discover how we can revolutionize your classroom.</p>
        </div>
        <a class="btn btn-success transition-3d-hover" href="https://taptab.io/" target="_blank">Explore TapTab <i class="fas fa-external-link-alt ml-1" /></a>
      </div>
    </div>
    <!-- ===== End CTA Section ===== -->

    <!-- ===== Other Products Section ===== -->
    <div class="container space-2 space-lg-3">
      <div class="mb-5 text-center">
        <h2>More by beNovelty</h2>
      </div>
      <div class="row" data-aos="fade-up">
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fabrixapi' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-fabrixapi-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>API Management Platform</h3>
              <p><strong>FabriXAPI</strong> is a one-stop API platform for providers to build and share APIs.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/openapihub' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-openapihub-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>Leading API Community</h3>
              <p><strong>OpenAPIHub</strong> is the leading API community with a powerful API directory and ecosystem.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fiberconnect' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-fiberconnect-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>API Connectivity for Financial Services</h3>
              <p><strong>FiberConnect</strong> uses Open API to boost SME & Startup efficiency with QR Code payments.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fabrixai' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-fabrixai-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>Low-Code AI Agent Platform</h3>
              <p><strong>FabriXAI</strong> is a low-code AI agent platform designed to simplify automation.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!-- ===== End Other Products Section ===== -->

  </main>
</template>

<script>
const $ = require('jquery')
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'
import 'bootstrap'
import '@/assets/js/hs.core.js'
import { mapGetters } from 'vuex'
export default {
  name: 'TapTab',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
    ])
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
  },
  created() {
  },
  methods: {
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'TapTab | Products | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/products/taptab' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'TapTab | Products | beNovelty' },
        { property: 'og:description', content: 'The next-gen AI agents designed for the education sector.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/products/taptab' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<style>
.gradient-x-overlay-sm-indigo::before {
  background-image: linear-gradient(45deg, transparent, rgba(4, 214, 214, 0.082));
  background-repeat: repeat-x;
}

.nav-box .nav-link.active {
    color: #29b8ac;
    background-color: #F8FCFC;
    box-shadow: inset 3px 0 0 #29b8ac;
    border-radius: 0px 5px 5px 0;
};
</style>
